import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {brands} from '@fortawesome/fontawesome-svg-core/import.macro'
import {Stack} from 'react-bootstrap'

const Social = () => {

    return (
        <Stack className={'list-group'} direction="horizontal" gap={2}>
            <a rel={'noreferrer'} href={'https://www.facebook.com/MegsanDiagnostics'} target={'_blank'}>
                <FontAwesomeIcon icon={brands('facebook-f')}/>
            </a>
            <a rel={'noreferrer'} href={'https://www.instagram.com/megsandiagnostics/'} target={'_blank'}>
                <FontAwesomeIcon icon={brands('instagram')}/>
            </a>
            <a rel={'noreferrer'} href={'https://www.youtube.com/channel/UCMioNrNABGGzoffKIvifOeg/'} target={'_blank'}>
                <FontAwesomeIcon icon={brands('youtube')}/>
            </a>
            <a rel={'noreferrer'} href={'https://twitter.com/MegsanDiagnos/'} target={'_blank'}>
                <FontAwesomeIcon icon={brands('twitter')}/>
            </a>
            <a rel={'noreferrer'} href={'https://www.linkedin.com/in/megsan-diagnostics//'} target={'_blank'}>
                <FontAwesomeIcon icon={brands('linkedin-in')}/>
            </a>
        </Stack>
    )
};

export default Social;
