import { Row } from 'react-bootstrap'

import PackagesBlock from '../../services/Block1'
import RelatedTests from '../../related-tests'
import healpingHeart from '../../../img/helping-heart.png'

import Executive from '../../../img/packages/Executive.png'
import Cardiac from '../../../img/packages/Cardiac.png'
import Healthy1 from '../../../img/packages/Healthy1.png'
import Healthy2 from '../../../img/packages/Healthy2.png'
import MasterHealthCheckup from '../../../img/packages/Master Health Checkup.png'
import WellWomen from '../../../img/packages/Well Women.png'
import Testimonial from '../../testimonial'
import feMaleImage from '../../../img/female.png'
import maleImage from '../../../img/male.png'
import diabeticbasic from '../../../img/DIABETIC PACKAGE (BASIC).png'
import diabeticexecutive from '../../../img/DIABETIC PACKAGE (EXECUTIVE).png'
import livewell from '../../../img/LIVEWELL-01-01 (1).png'
const Packages = () => {


  let relatedPackages = {
    cbc: {
      title: 'MEGSAN LIVE WELL PACKAGE',
      description: 'Ideal for people who suspect having lifestyle disorders such as anemia, anxiety, etc',
      imagePath: livewell,
      price: 699
    },
    rtpcr: {
      title: 'DIABETIC PACKAGE (BASIC)',
      description: 'Ideal for people who lead a stressful life, who suffer from diabetes, complain of allergy, etc',
      imagePath: diabeticbasic,
      price: 1499
    },
    ppbs: {
      title: 'DIABETIC PACKAGE (EXECUTIVE)',
      description: 'Ideal for people who lead a fast life and often complain of fatigue, tiredness and joint pain',
      imagePath: diabeticexecutive,
      price: 2499
    },
  };

  let popularPackages = {
    cbc: {
      title: 'MASTER HEALTH CHECK UP',
      description: 'Ideal for people who are getting their health check up done for the first time with the advantage of advanced Lipid Testing',
      imagePath: MasterHealthCheckup,
      price: 2999,
      totalTests: 30
    },
    rtpcr: {
      title: 'EXECUTIVE HEALTH CHECK UP',
      description: 'Ideal for people who are getting their health check up done for the first time',
      imagePath: Executive,
      price: 4899,
      totalTests: 35
    },
    ppbs: {
      title: 'CARDIAC PROFILE',
      description: 'Ideal for men above the age of 60 years, who want to get a comprehensive health check up done',
      imagePath: Cardiac,
      price: 299,
      totalTests: 5
    },
    cbc2: {
      title: 'WELL WOMEN PACKAGE',
      description: 'Ideal for female above the age of 60 years, who want to get a comprehensive health check up done',
      imagePath: WellWomen,
      price: 6599,
      totalTests: 35
    },
    rtpcr2: {
      title: 'MEGSAN HEALTHY 1',
      description: 'Ideal for women between the ages 41-50 years,who want to get a comprehensive health check up done',
      imagePath: Healthy1,
      price: 2890,
      totalTests: 43
    },
    ppbs2: {
      title: 'MEGSAN HEALTHY 2',
      description: 'Ideal for women between the ages 41-50 years,who want to get a comprehensive health check up done',
      imagePath: Healthy2,
      price: 4370,
      totalTests: 46
    },
  };

  let testimonial = {
    user1: {
      userName: 'Satya Vinay',
      description: 'Went there for Covid RTPCR test,  the results came very fast,  within 5-6 hours of test. The place ambience was good,  clean and it was not crowded during my visit.',
      imagePath: feMaleImage,
    },
    user2: {
      userName: 'Immanuel Dibbagalla',
      description: 'This is 4th time with Megsan but all the times it was great experience.Staff knows all the details and they are very polite. Specially the kompally branch was excellent. Reports recd by midnight without any delays. Best diagnostic centre',
      imagePath: maleImage,
    },
    user3: {
      userName: 'Vadithya Thirupal naik',
      description: 'Good service excellent ambience reports on time n overall am very happy with the organisation...',
      imagePath: feMaleImage,
    }
  };

  return (
    <div className={'Packages-content'}>
      <Row className={'related-tests-container-fluid'} >
        <RelatedTests customTitle={'Popular Health Checkup Packages'} relatedPackages={relatedPackages} />
      </Row>
      <Row className={'related-tests-container-fluid'} >
        <RelatedTests customTitle={'Our Popular Health Packages'} type={'basic'} relatedPackages={popularPackages} />
      </Row>
      <Row className={'testimonial-container-fluid'} >
        <Testimonial testimonial={testimonial} />
      </Row>
    </div>
  )
}

export default Packages
