import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Carousel
} from 'react-bootstrap'
import homeBannerImage from '../../img/Website new-png.png'
import homeBannerImage2 from '../../img/Website png.png'
import homeBannerImage3 from '../../img/Branches.png'
import homeBannerImage4 from '../../img/Equipment.png'
import homeBannerImage5 from '../../img/Profile.png'
import homeBannerImage6 from '../../img/local location.png'
import homeBannerImage7 from '../../img/Womens day website-01.png'
import homeBannerImage8 from '../../img/Focus.png'
import {BrowserView, isMobile} from 'react-device-detect';
import { useState } from 'react'
import './style.scss'
import ReactHtmlParser from 'react-html-parser';

const HomeBanner = () => {

  /* eslint-disable no-unused-vars */
  const [searchValue, setSearchValue] = useState('')
  const [searchLocation, setSearchLocation] = useState('')
  const [validated, setValidated] = useState(false)

  const handleSubmit = (event) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    }

    setSearchLocation('')
    setSearchValue('')
    setValidated(true)
    event.preventDefault()
    event.stopPropagation()
  }

  let lookingFor = [
    'Family Medicine',
    'COVID-19',
    'Mens Health',
    'Blood Test',
    'Lipid Profile',
    'Kidney Function Test',
  ]

  /* eslint-enable no-unused-vars */

  const BannerBody = ( { title, description, link }) => {

    return <Card>
      <Card.Body>
        <Card.Title>{ReactHtmlParser(title)}</Card.Title>
        <Card.Text>{ReactHtmlParser(description)}</Card.Text>
        <div className={'action-link'}>
          <Button variant="primary">Know More</Button>
        </div>
      </Card.Body>
    </Card>
  };

  const ImageComponent = ( { image, altText }) => {

    return <img
        className={ isMobile ? 'd-block w-100' : 'home-banner-content-image' }
        src={image}
        alt={altText}
    />
  }

  const TextBody = ({ title, description, link, image, altText}) => {

    return isMobile ? <Carousel.Caption>
        <Col md={5} className={'home-banner-content-text'}>
          <BannerBody title={title} description={description} />
        </Col>
        <BrowserView className={'col home-banner-content-image'}>
          <ImageComponent image={image} altText={altText} />
        </BrowserView>
      </Carousel.Caption> : <Row>
      <Col md={5} className={'home-banner-content-text'}>
        <BannerBody title={title} description={description} />
      </Col>
      <BrowserView className={'col home-banner-content-image'}>
        <ImageComponent image={image} altText={"altText"} />
     
      </BrowserView>
    </Row>
  }

  return (
        <Container className={'home-banner'}>
      <Container className={'home-banner-content'}>
        <Carousel variant="dark">
          <Carousel.Item>
            { isMobile ? <ImageComponent image={homeBannerImage} altText={"First slide"} /> : '' }
            <TextBody
                title={'Book a RT-PCR Test now<br/> From Anywhere.'}
                description={'Book A RT-PCR Home Collection now<br/>. We care for your health.'}
                image={homeBannerImage}
                altText={"First slide"}
            />
          </Carousel.Item>
          <Carousel.Item>
            { isMobile ? <ImageComponent image={homeBannerImage2} altText={"Second slide"} /> : '' }
            <TextBody
                title={'Cutting-Edge Radiology Technology'}
                description={'Our radiology team is composed of highly experienced professionals. <br/>We use the latest technology and techniques to ensure that our patients receive the most accurate diagnosis possible. '}
                image={homeBannerImage2}
                altText={"Second slide"}
            />
			</Carousel.Item>
          <Carousel.Item>
            { isMobile ? <ImageComponent image={homeBannerImage3} altText={"Third slide"} /> : '' }
            <TextBody
                title={'High-Tech Diagnostic Centers: Clean, Safe, Accurate.'}
                description={'At our Diagnostic Centers, we strive to provide the highest quality of diagnostics and care to our patients. Our centers have been designed with a focus on creating a comfortable and pleasant atmosphere for our patients. We have taken great care in ensuring that the interior design is modern and inviting, while also providing a culture of cleanliness and hygiene.'}
                image={homeBannerImage3}
                altText={"Third slide"}
            />
			</Carousel.Item>
          <Carousel.Item>
            { isMobile ? <ImageComponent image={homeBannerImage4} altText={"Fourth slide"} /> : '' }
            <TextBody
                title={'Advanced Diagnostic Technology<br/>Accurate Results Guaranteed.'}
                description={'Our pathology and radiology centers are equipped with the latest, state-of-the-art technology to deliver precise and accurate results. Our team uses only the best equipment to ensure the highest quality diagnostic services for our patients.'}
                image={homeBannerImage4}
                altText={"fourth slide"}
            />
			</Carousel.Item>
          <Carousel.Item>
            { isMobile ? <ImageComponent image={homeBannerImage5} altText={"fifth slide"} /> : '' }
            <TextBody
                title={'Caring for Patients and Employees: Megsan Mission.'}
                description={'Megsan Diagnostics is a leading healthcare provider in India, with over 6 Lakhs customers and 600 plus employees. We offer a wide range of health packages to ensure that its customers get the best care and treatment.'}
                image={homeBannerImage5}
                altText={"Fifth slide"}
            />
			</Carousel.Item>
          <Carousel.Item>
            { isMobile ? <ImageComponent image={homeBannerImage6} altText={"sixth slide"} /> : '' }
            <TextBody
                title={'3 States 14 Cities 32 Locations'}
                description={'Megsan Diagnostics is proud to serve communities across three states with its state-of-the-art diagnostic centers. With 32 convenient locations in 14 cities, we are dedicated to providing accessible and quality healthcare services to patients near and far.'}
                image={homeBannerImage6}
                altText={"Sixth slide"}
            />
          </Carousel.Item>
		  <Carousel.Item>
            { isMobile ? <ImageComponent image={homeBannerImage7} altText={"seventh slide"} /> : '' }
            <TextBody
                title={'International Womens Day'}
                description={'Empower your health with our exclusive womens day offer. Comprehensive check-ups and personalized care for all women.'}
                image={homeBannerImage7}
                altText={"Seventh slide"}
            />
          </Carousel.Item>
		  <Carousel.Item>
            { isMobile ? <ImageComponent image={homeBannerImage8} altText={"eith slide"} /> : '' }
            <TextBody
                title={'Expanding Healthcare Reach: Megsan Acquires Focus Diagnostics.'}
                description={'Megsan Diagnostics is proud to announce the acquisition of Focus Diagnostics, expanding our reach and commitment to providing quality healthcare services. This partnership will allow us to serve even more communities with state-of-the-art diagnostic solutions.'}
                image={homeBannerImage8}
                altText={"eigth slide"}
            />
          </Carousel.Item>
        </Carousel>
      </Container>
      {/*<Container className={'home-search-content'}>*/}
      {/*  <Row>*/}
      {/*    <Col className={'home-search'}>*/}
      {/*      <Card>*/}
      {/*        <Card.Body>*/}
      {/*          <Form noValidate validated={validated} onSubmit={handleSubmit}>*/}
      {/*            <Row className={'home-search-form'}>*/}
      {/*              <Col md={6} className={'home-search-test'}>*/}
      {/*                <InputGroup className="mb-3">*/}
      {/*                  <InputGroup.Text id="basic-addon1">*/}
      {/*                    <FontAwesomeIcon icon={faSearch}/>*/}
      {/*                  </InputGroup.Text>*/}
      {/*                  <Form.Control*/}
      {/*                    id={'search-value'}*/}
      {/*                    type="text"*/}
      {/*                    required*/}
      {/*                    defaultValue={searchValue}*/}
      {/*                    placeholder="Type to search for any test ( ex: blood, health.... ) "*/}
      {/*                  />*/}
      {/*                </InputGroup>*/}
      {/*              </Col>*/}
      {/*              <Col md={4} className={'home-search-location'}>*/}
      {/*                <InputGroup className="mb-3">*/}
      {/*                  <InputGroup.Text id="basic-addon1">*/}
      {/*                    <FontAwesomeIcon icon={faLocation}/>*/}
      {/*                  </InputGroup.Text>*/}
      {/*                  <Form.Control*/}
      {/*                    type="text"*/}
      {/*                    id={'search-location'}*/}
      {/*                    required*/}
      {/*                    defaultValue={searchLocation}*/}
      {/*                    placeholder="Set Your Location"*/}
      {/*                  />*/}
      {/*                </InputGroup>*/}
      {/*              </Col>*/}
      {/*              <Col sm={12} md={1} className={'home-search-submit'}>*/}
      {/*                <Button variant="light" type={'submit'}>*/}
      {/*                  <FontAwesomeIcon icon={faSearch}/>*/}
      {/*                </Button>*/}
      {/*              </Col>*/}
      {/*            </Row>*/}
      {/*          </Form>*/}
      {/*          <Row className={'home-search-text'}>*/}
      {/*            <Col className={'home-search-lookingfor'}>*/}
      {/*              You may be looking for*/}
      {/*            </Col>*/}
      {/*          </Row>*/}
      {/*          <Row className={'home-search-text'}>*/}
      {/*            <Col className={'home-search-lookingfor-buttons'}>*/}
      {/*              {*/}
      {/*                lookingFor.map((name, i) => {*/}
      {/*                  return (<Button key={i} variant="light">{name}</Button>)*/}
      {/*                })*/}
      {/*              }*/}
      {/*            </Col>*/}
      {/*          </Row>*/}
      {/*        </Card.Body>*/}
      {/*      </Card>*/}
      {/*    </Col>*/}
      {/*  </Row>*/}
      {/*</Container>*/}
    </Container>
  )
}

export default HomeBanner
