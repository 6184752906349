import { Col, Row } from 'react-bootstrap'
import directorImage from '../../../img/directors-1.png'
import directorImage2 from '../../../img/directors-2.png'
import OurDirectors from '../../our-directors'
import Services from '../../services'
import serviceVision from '../../../img/services/s1.png'
import serviceValue from '../../../img/services/service-value.png'
import serviceMission from '../../../img/services/service-mission.png'

import s1 from '../../../img/services/1.png'
import s2 from '../../../img/services/2.png'
import s3 from '../../../img/services/3.png'
import s4 from '../../../img/services/4.png'
import s5 from '../../../img/services/5.png'
import s6 from '../../../img/services/6.png'

const AboutUs = () => {
  let dList = {
    d1: {
      title: 'Founder & MD',
      imagePath: directorImage,
      content: 'Mr.Jayapal Reddy is a scientist and entrepreneur, leading Megsan since 2014. He brings with him over 15 years of experience in various roles in the pharmaceutical and contract testing industry. Under his leadership, company significantly transformed from a small-sized domestic operation into a global independent product testing company. Jayapal believes in sustainable and innovative business practices. Towards this, he is engaged with strengthening the company capabilities. Jayapal holds a Master\'s degree from Sri Venkateswara University, INDIA.',
      position: 'left',
    },
    d2: {
      title: 'Co-Founder & Director',
      imagePath: directorImage2,
      content: 'Jyothirmai joined the company in 2014 as Co-Founder and Director of Megsan. She brings with her over 14 years of experience in various roles in the pharmaceutical and contract testing industry. Jyothirmai leads core team at Megsan, instrumental in building strong quality systems and heavily involved in setting overall company strategy. Jyothirmai holds a Master\'s degree from Sri Venkateswara University, INDIA. An alumnus of the ISB-Goldman Sachs Women Entrepreneur programme, she was awarded the best prize for “Certificate Programme for Women Entrepreneur” held 2016 at the Indian School of Business, Hyderabad.',
      position: 'right',
    },
  }

  let sList = {
    s1: {
      title: 'Vision',
      imagePath: serviceVision,
      content: 'The Vision to Create Megsan Diagnostics was driven by the Philosophy to provide high quality Standards Accurate Test Results at Affordable Prices to the Masses.',
      position: 'top',
    },
    s2: {
      title: 'Mission',
      imagePath: serviceMission,
      content: 'The mission is to provide affordable diagnostic solutions for a wide range of tests available for everyone',
      position: 'top',
    },
    s3: {
      title: 'Value',
      imagePath: serviceValue,
      content: 'These values, which guide Megsan Diagnostic\'s mission to this day, are an expression of the vision and intent of our founders.',
      position: 'top',
    },
  }

  let serviceList = Object.entries(sList).map(([key, value]) => {
    return <Col md={4} key={key}><Services position={value.position}
                                           title={value.title}
                                           imagePath={value.imagePath}
                                           content={value.content}/></Col>
  })


  let vList = {
    s1: {
      title: 'New age Diagnostics ',
      imagePath: s1,
      content: 'At Megsan, we incorporate contemporary best practices in radiology & pathology and augment this effort with exemplary hospitality, quality and consistency in our services.',
      position: 'bottom',
    },
    s2: {
      title: 'Radiology & Pathology',
      imagePath: s2,
      content: 'We provide both Radiology and pathology services which is convenient for the customer at a one stop for all diagnostics needs.',
      position: 'bottom',
    },
    s3: {
      title: 'New Age reporting',
      imagePath: s3,
      content: 'A click of a button and our customers get detailed reporting analysis of there past and present to formulate a detailed health graphical comparison report for all there test.',
      position: 'bottom',
    },
    s4: {
      title: 'Technology advancement',
      imagePath: s4,
      content: 'State of the art cloud services are incorporated at Megsan to make digital reports availability at your fingertips for a lifetime.',
      position: 'bottom',
    },
    s5: {
      title: 'Home sample collection',
      imagePath: s5,
      content: 'On receiving a request through our Website, App or call center a Megsan trained technicians arrives to collect samples. Reports can be send to your phone via sms/email or can be collected at any of our centers.',
      position: 'bottom',
    },
    s6: {
      title: 'R&D Department',
      imagePath: s6,
      content: 'We keep adapting. With this intention, we have an in-house R&D department who innovate products that improve the diagnostics precision for the common healthcare dilemmas.',
      position: 'bottom',
    },
  }

  let visionList  = Object.entries(vList).map(([key, value]) => {
    return <Col sm={12} md={4} key={key}><Services position={value.position}
                                           title={value.title}
                                           imagePath={value.imagePath}
                                           content={value.content}/></Col>
  })



  return (
    <div className={'about-us-content'}>
      <div className={'directors'}>
        <h2>Its Good to know<br/>
          why we never rest</h2>
        <p>One of the specialty diagnostic centre and pathology laboratory in
          India an end-to-end healthcare service provider.</p>
        <p>Welcome to Megsan Diagnostics, one of the best diagnostic centers in Hyderabad, Bangalore, Rajahmundry, Nellore and Kurnool, providing high-quality medical imaging and diagnostic services to patients throughout the city. We have a team of experienced radiologists, technicians, and staff who are dedicated to providing accurate and timely diagnoses and personalized care to our patients.<br/>

At Megsan Diagnostics, we offer a wide range of services, including MRI scans, CT scans, X-rays, ultrasounds, TMT tests, 2D echocardiograms, EEG tests, and pathology labs. Our team uses state-of-the-art technology and equipment to ensure that our patients receive the most advanced and effective diagnostic testing available.<br/>

One of the key benefits of choosing Megsan Diagnostics is our convenient location. We have diagnostic centers in several locations throughout the city, including in Kompally, Banjara Hills, KPHB, Chintal, BN Reddy Nagar, Kondapur and BHEL . This makes it easy for patients to find a center that is close to them and to get the care they need without having to travel long distances.<br/>

In addition to our on-site diagnostic centers, we also offer home collection services for patients who may not be able to visit our centers in person. Our free home collection services make it easy and convenient for patients to get the testing they need without leaving their homes. We understand that it can be difficult to travel to a diagnostic center, particularly for those who are elderly or have mobility issues. That's why we are committed to providing accessible and personalized care to all of our patients.<br/>

Our pathology labs are staffed by experienced technicians and are equipped with the latest technology to ensure that all tests are performed accurately and efficiently. We offer a range of blood tests and other diagnostic tests to help diagnose and monitor a variety of medical conditions. Our team is committed to providing accurate and timely results, so patients can get the care they need as quickly as possible.<br/>

At Megsan Diagnostics, we pride ourselves on providing exceptional customer service. Our team is committed to ensuring that all patients feel comfortable, informed, and well-cared-for throughout the diagnostic testing process. Whether you are visiting us for a routine checkup or a more complex medical issue, you can trust that you are in good hands with our team.<br/>

If you are searching for high-quality diagnostic and imaging services in Hyderabad, look no further than Megsan Diagnostics. Our team of experienced professionals is dedicated to providing personalized care and accurate diagnoses to all of our patients. Contact us today to learn more about our services or to schedule an appointment.</p>
        <div className={'directors-view'}><OurDirectors directorsList={dList}/></div>
        <div className={'directors-vision'}>
          <Row> {serviceList} </Row>
        </div>
      </div>
      <div className={'vision'}>
        <Row className={'vision-title'}>
          <Col md={8}>
            <h2>What makes Megsan diagnostics
              different from others</h2>
          </Col>
        </Row>
        <Row className={'vision-description'}>
          <Col md={12}>
            Providing quality diagnostics services is our passion, improving the
            quality of lives is our reward. Here are
            a few pointers that separate us from the crowd.
          </Col>
        </Row>
        <div className={'vision-list'}>
          <Row> {visionList} </Row>
        </div>
      </div>
    </div>
  )
}

export default AboutUs
