import './style.scss'
import { Col, Container, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

const FrequentlyBooked = ( { relatedPackages } ) => {


  return (
    <div>
    <Container className={'frequently-booked-container'}>
      <Row className={'frequently-booked-container-header'}>
        <Col sm={12} md={8}>
          <div className={'frequently-booked-container-header-title'}><h3>Frequently Booked Packages</h3></div>
          <div className={'frequently-booked-container-header-list'}>
            <Row>
              <Col xs={6}><span><FontAwesomeIcon icon={solid('certificate')} /></span><span>100% Certified Lab</span></Col>
              <Col xs={6}><span><FontAwesomeIcon icon={solid('face-smile-beam')} /></span><span>1000 Happy Customers</span></Col>
              <Col xs={6}><span><FontAwesomeIcon icon={solid('flask')} /></span><span>Free Sample Collection</span></Col>
              <Col xs={6}><span><FontAwesomeIcon icon={solid('clipboard-list')} /></span><span>Accurate Reports</span></Col>
            </Row>
          </div>
        </Col>
        <Col xs={4}>&nbsp;</Col>
      </Row>
    </Container>
    </div>
  );
}

export default FrequentlyBooked;
