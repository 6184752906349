import './style.scss'
import React from 'react'
import { Row, Container, Col } from 'react-bootstrap'

const PageNotFound = () => {

  return (
    <Container className="page_404">
      <Row>
        <Col xs={12}>
          <div className="col-sm-10 col-sm-offset-1  text-center">
            <div className="four_zero_four_bg">
              <h1 className="text-center"> </h1>
            </div>

            <div className="contant_box_404">
              <h3 className="h2">
                Look like you're lost
              </h3>
              <p>the page you are looking for not avaible!</p>
              <a href="/" className="link_404">Go to Home</a>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default PageNotFound
