import './style.scss'
import { Col, Row, Figure } from 'react-bootstrap'
import {isMobile} from "react-device-detect";

const OurDirectors = ( { directorsList } ) => {

  const RightBlock = ({ title, imagePath, content }) => {
    return <Row>
      <Col md={9} className={'our-directors-list-content'}>{content}</Col>
      <Col md={3} className={'our-directors-list-image'}><Figure>
        <Figure.Image
          rounded={true}
          alt="171x180"
          src={imagePath}
        />
      </Figure>
        <div className={'figure-caption'}>{title}</div>
      </Col>
    </Row>
  }
  
  const LeftBlock = ({ title, imagePath, content }) => {
    return <Row>
      <Col md={3} className={'our-directors-list-image'}>
        <Figure>
          <Figure.Image
            rounded={true}
            alt="171x180"
            src={imagePath}
          />
          <Figure.Caption>
            {title}
          </Figure.Caption>
        </Figure>
      </Col>
      <Col className={'our-directors-list-content'} md={9}>{content}</Col>
    </Row>
  }
  
  let list = Object.entries(directorsList).map(([key, value]) => {
    return value.position === 'right' && !isMobile ? <li className={'our-directors-list-right'} key={key} ><RightBlock {...value} /></li> : <li className={'our-directors-list-left'} key={key} ><LeftBlock {...value} /></li>;
  })

  return (
    <div className={'our-directors'}>
      <Row className={'our-directors-title'}>
        <h3>Our Directors</h3>
      </Row>
      <Row className={'our-directors-list'}>
        <ul>{list}</ul>
      </Row>
    </div>
  )
}

export default OurDirectors;
