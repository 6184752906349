import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import HeaderTop from './components/header-top';
import HeaderMain from './components/header-main'
import Home from './components/home'
import Footer from './components/footer';
import {Container} from 'react-bootstrap'
import Pages from './components/pages'
import PageNotFound from './components/404'
import {Route, Routes} from 'react-router-dom'
import Whatsapp from './components/whatsapp'
import {useState} from "react";
import {Helmet} from "react-helmet";

function App() {
    const pagePATH = '/' === window.location.pathname ? 'home' : '';

    const [menuActive, setMenuActive] = useState(false);
    const [ locationActive, setLocationActive ] = useState(false);

    return (
        <div className={'App ' + pagePATH}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Megsan Diagnostics</title>
                <link rel="canonical" href="http://megsandiagnostics.com/" />
            </Helmet>
            <div className={'App-header-top'}>
                <HeaderTop activateMenu={setMenuActive} activteLocationModel={locationActive}/>
            </div>
            <div className={'App-container'}>
                <Container fluid>
                    <HeaderMain activateMenu={menuActive} setShowLocation={setLocationActive} setMenuActive={setMenuActive} />
                    <Routes>
                        <Route path={'/'} element={<Home/>}/>
                        {/*<Route path={'/packages'} element={<Home />} />*/}
                        {/*<Route path={'/tests'} element={<Home />} />*/}
                        <Route path={'/our-services'}
                               element={<Pages title={'Our Services'} template={'our-service'}/>}/>
                        <Route path={'/home-collections'}
                               element={<Pages title={'Contact Us'} template={'contact-us'}/>}/>
                        <Route path={'/about-us'} element={<Pages title={'About Us'} template={'about-us'}/>}/>
                        <Route path={'/contact-us'} element={<Pages title={'Contact Us'} template={'contact-us'}/>}/>
                        <Route path="*" element={<PageNotFound/>}/>
                    </Routes>
                    <Whatsapp/>
                    <Footer/>
                </Container>
            </div>
        </div>
    );
}

export default App;
