import './style.scss'
import {Container, Row, Col, Button, Nav} from 'react-bootstrap'
import {MobileView} from 'react-device-detect';

function HeaderMain({activateMenu, setShowLocation, setMenuActive}) {
    const bookTest = (url) => {
        window.open(url, '_self').focus();
    }

    const menuClass = activateMenu ? 'header container-fluid activate-header' : 'header container-fluid';

    return (
        <div className={menuClass}>
            <Container>
                <Row className={'header-main'}>
                    <Col md={8} className={'header-main-menu'}>
                        <Nav
                            activeKey="/"
                            //onSelect={(selectedKey) => clearMenu()}
                        >
                            <Nav.Item>
                                <Nav.Link href="/">Home</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link href="/about-us">About Us</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link href="/our-services">Our Services</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link href="/packages">Packages</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link
                                    href="http://13.234.130.95/MEGSANDIAGNOSTICS/Design/Onlinelab/default.aspx"
                                    target={'_blank'}>Download Reports</Nav.Link>
                            </Nav.Item>
                            <MobileView>
                                <Nav.Item>
                                    <Nav.Link href="/contact-us">Contacts Us</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        href="https://play.google.com/store/apps/details?id=com.itdose.b2capp.megsan">Download
                                        App</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="#">Sample Tracker</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="#/action-1" onClick={() => setShowLocation(true)}>Our
                                        Branches</Nav.Link>
                                </Nav.Item>
                            </MobileView>
                        </Nav>
                    </Col>
                    <Col md={2} className={'header-main-action'}>
                        <Button onClick={() => bookTest('/home-collections')}>Book a Home
                            Visit</Button>
                    </Col>
                    <Col md={2} className={'header-main-action'}>
                        <Button onClick={() => bookTest('/contact-us')}>Book a test</Button>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default HeaderMain
