import {
  Row,
  Col,
  Container
} from 'react-bootstrap'
import './style.scss'
import needHelpDoctor from '../../img/need-help/doctor.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import {isMobile} from "react-device-detect";

const NeedHelp = () => {

  return (
    <Container className={'need-help-container'}>
      <Row className={'need-help-content'}>
        <Col xs={ isMobile ? '' : 2 } className={'need-help-image'}>
          <img src={needHelpDoctor} alt={'need-help-doctor'}/>
        </Col>
        {
          isMobile ? '' : <Col xs={7} className={'need-help-info'}>
            <div>
              <h4>Need help with booking your test?</h4>
              <p>Our experts are here to help you</p>
            </div>
          </Col>
        }
        <Col className={'need-help-contact'}>
          <div className={'need-help-contact-telephone-content'}>
            <Row>
              <span>Get Help</span>
            </Row>
            <Row>
              <Col xs={2} className={'need-help-contact-telephone-icon'}>
                <FontAwesomeIcon icon={faPhone}/>
              </Col>
              <Col xs={10} className={'need-help-contact-telephone-text'}>
                <a href={'tel:1800120303303'}> 1800 120 303 303 </a> <br/>
                <a href={'tel:+919121377123'}> +91 91213 77123 </a>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default NeedHelp
