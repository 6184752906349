import './style.scss';
import {Container, Row, Col, Stack, Button, Modal} from 'react-bootstrap';
import {faPhone, faList} from '@fortawesome/free-solid-svg-icons'
import Social from '../social'
import {useEffect, useState} from 'react'
import companyLogo from '../../img/logo-white.png'
import Info from '../info'
import {BrowserView, MobileView} from 'react-device-detect';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


function HeaderTop({activateMenu, activteLocationModel}) {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleBranchList = (value) => setShow(value);
    const LocationModal = () => {
  const [showModal, setShowModal] = useState(true);

  const handleClose = () => setShowModal(false);
  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Location Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        We at Megsan Diagnostics would like to know your location so we can provide you with the best possible service.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Not Now
        </Button>
        <Button variant="primary" onClick={handleClose}>
          Allow
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
    useEffect(() => {
        setShow(activteLocationModel)
    }, [activteLocationModel])


    const LocationModel = () => {
        return (
            <Modal centered size="lg" className={'location-model'} show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title><img src={companyLogo} alt={'Logo'}/></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h2>Our Branches</h2>
                    <div className={'location-list'}>
                        <div className={'location-list-category'}>
                            <Button variant="secondary" size="sm">Telangana</Button>
                        </div>
                        <div className={'location-list-sub-category'}>
                            <Button onClick={() => {
							window.open('https://g.co/kgs/FzWNoz', 'popup',
            'width=500,height=500,left=100,top=100,resizable,scrollbars,toolbar=0,personalbar=0,status=0')
                        }}	variant="light" size="sm">Kompally</Button>
                            <Button onClick={() => {
							window.open('https://g.co/kgs/NN8W3G', 'popup',
            'width=500,height=500,left=100,top=100,resizable,scrollbars,toolbar=0,personalbar=0,status=0')
                        }}	variant="light" size="sm">Banjara Hills</Button>
                             <Button onClick={() => {
							window.open('https://g.co/kgs/jzHqED', 'popup',
            'width=500,height=500,left=100,top=100,resizable,scrollbars,toolbar=0,personalbar=0,status=0')
                        }}	variant="light" size="sm">KPHB</Button>
                           <Button onClick={() => {
							   window.open('https://g.co/kgs/oGrMHG', 'popup',
            'width=500,height=500,left=100,top=100,resizable,scrollbars,toolbar=0,personalbar=0,status=0')
                        }}	variant="light" size="sm">Kondapur</Button>
                           <Button onClick={() => {
							   window.open('https://g.co/kgs/2nFBnd', 'popup',
            'width=500,height=500,left=100,top=100,resizable,scrollbars,toolbar=0,personalbar=0,status=0')
                        }}	variant="light" size="sm">Chintal</Button>
                            <Button onClick={() => {
							   window.open('https://g.co/kgs/4rN3cB', 'popup',
            'width=500,height=500,left=100,top=100,resizable,scrollbars,toolbar=0,personalbar=0,status=0')
                        }}	variant="light" size="sm">BHEL</Button>
                            <Button onClick={() => {
							   window.open('https://g.co/kgs/K5oKer', 'popup',
            'width=500,height=500,left=100,top=100,resizable,scrollbars,toolbar=0,personalbar=0,status=0')
                        }}	variant="light" size="sm">BN Reddy</Button>
                             <Button onClick={() => {
							   window.open('https://g.co/kgs/SxoEj8', 'popup',
            'width=500,height=500,left=100,top=100,resizable,scrollbars,toolbar=0,personalbar=0,status=0')
                        }}	variant="light" size="sm">Punjagutta</Button>
                            <Button onClick={() => {
							   window.open('https://g.co/kgs/Hg9esH', 'popup',
            'width=500,height=500,left=100,top=100,resizable,scrollbars,toolbar=0,personalbar=0,status=0')
                        }}	variant="light" size="sm">Secunderabad</Button>
                            <Button variant="light" size="sm">Madeenaguda</Button>
                            <Button variant="light" size="sm">Karimnagar</Button>
                            <Button variant="light" size="sm">Warangal</Button>
                        </div>
                    </div>
                    <div className={'location-list'}>
                        <div className={'location-list-category'}>
                            <Button variant="secondary" size="sm">Andhra Pradesh</Button>
                        </div>
                        <div className={'location-list-sub-category'}>
                            <Button onClick={() => {
							   window.open('https://g.co/kgs/BpcQki', 'popup',
            'width=500,height=500,left=100,top=100,resizable,scrollbars,toolbar=0,personalbar=0,status=0')
                        }}	variant="light" size="sm">Rajahmundry</Button>
                             <Button onClick={() => {
							   window.open('https://g.co/kgs/K4VB9g', 'popup',
            'width=500,height=500,left=100,top=100,resizable,scrollbars,toolbar=0,personalbar=0,status=0')
                        }}	variant="light" size="sm">Kurnool</Button>
                            <Button variant="light" size="sm">Nellore</Button>
                            <Button variant="light" size="sm">Guntur</Button>
                            <Button variant="light" size="sm">Vizag</Button>
                        </div>
                    </div>
                    <div className={'location-list'}>
                        <div className={'location-list-category'}>
                            <Button variant="secondary" size="sm">Karnataka</Button>
                        </div>
                        <div className={'location-list-sub-category'}>
                            <Button variant="light" size="sm">Bangalore</Button>
                            <Button variant="light" size="sm">Kangeri</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    const [isMenuActive, setIsMenuActive] = useState(!!activateMenu);

    const handleMenu = () => {
        setIsMenuActive(!isMenuActive);
        activateMenu(isMenuActive);
    }

    return (
        <Container className={'header'}>
            <Row className={'header-top'}>
                <Col sm={12} md={2} className={'header-top-telephone'}>
                    <BrowserView className={'row header-main-logo'}>
                        <a href={'/'}><img src={companyLogo} alt={'Logo'}/></a>
                    </BrowserView>
                    <MobileView className={'header-main-logo row'}>
                        <Col xs={10}>
                            <a href={'/'}><img src={companyLogo} alt={'Logo'}/></a>
                        </Col>
                        <Col xs={2}>
                            <Button
                                variant="notice"
                                onClick={() => handleMenu()}>
                                <FontAwesomeIcon icon={faList}/>
                            </Button>
                        </Col>
                    </MobileView>
                </Col>
                <BrowserView className={'header-top-menu col-md-7'}>
                    <Stack className={'list-group'} direction="horizontal">
                        <Button onClick={() => handleBranchList(true)}>Our Branches</Button>
                        <Button onClick={() => {
                            window.location.href = '/contact-us'
                        }}>Contacts Us</Button>
                        <Button onClick={() => {
                            window.location.href = 'https://play.google.com/store/apps/details?id=com.itdose.b2capp.megsan'
                        }}>Download App</Button>
                        <Button>Sample Tracker</Button>
                    </Stack>
                </BrowserView>
                <LocationModel/>
                <Col md={3} className={'header-top-social'}>
                    <BrowserView className={'row'}>
                        <Info title={'Get Help'}
                              content={"<a href='tel:+1800120303303'>1800 120 303 303</a> <br/> <a href='tel:+919121377123'>+91 91213 77123</a>"}
                              fontAwesomeIconName={faPhone}/>
                    </BrowserView>
                    <Row>
                        <Social/>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default HeaderTop;
