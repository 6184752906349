import './style.scss'

import statsImage from '../../img/stats-2.png'
import { Row, Container, Col, ListGroup } from 'react-bootstrap'

const Stats = () => {

  return (
    <Container className={'home-stats-container'}>
      <Row>
        <Col xs={7}>
          <h2>“ Megsan is your one stop <br/>
            home for Health & Wellness ”</h2>
          <div className={'status'}>
            <ListGroup horizontal>
              <ListGroup.Item><h5>140</h5>Tests</ListGroup.Item>
              <ListGroup.Item><h5>10K</h5>Patients</ListGroup.Item>
              <ListGroup.Item><h5>100</h5>Packages</ListGroup.Item>
              <ListGroup.Item><h5>20</h5>Offers</ListGroup.Item>
            </ListGroup>
          </div>
          <h2>Providing Quality Care <br/>
            With Patience.</h2>
        </Col>
        <Col xs={5}><img src={statsImage} alt={'Status thumbnail'}/></Col>
      </Row>
    </Container>
  )
}

export default Stats
