import {Col, Container, Nav, Row} from 'react-bootstrap'
import companyLogo from '../../img/logo.png'
import './style.scss'
import Social from '../social'
import {faPhone, faMessage} from '@fortawesome/free-solid-svg-icons'
import Info from '../info'

const Footer = () => {

    return (
        <Container fluid={true} className={'footer'}>
            <Container className={'footer-container'}>
                <Row className={'footer-content'}>
                    <Col md={4} sm={12} className={'footer-content-logo'}>
                        <img src={companyLogo} alt={'Logo'}/>
                        <p>
                            H.No. 8-2-248/1/7/54, Plot no. 54, Nagarjuna Hills, Hyderabad,<br/> Telangana 500082.
                        </p>
                    </Col>
                    <Col md={4} sm={12} className={'footer-content-menu'}>
                        <Nav
                            activeKey="/home"
                            onSelect={(selectedKey) => console.log(`selected ${selectedKey}`)}
                        >
                            <Col>
                                <Nav.Item>
                                    <Nav.Link href="/packages">Packages</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="/our-services">Services</Nav.Link>
                                </Nav.Item>
                               
                                <Nav.Item>
                                    <Nav.Link href="/about-us">About Us</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="/blog">Blog</Nav.Link>
                                </Nav.Item>
                            </Col>
                        </Nav>
                    </Col>
                    <Col md={4} sm={12} className={'footer-content-contact'}>
                        <Info title={'Send A Query'} content={'info@megsandiagnostics.com'}
                              fontAwesomeIconName={faMessage}/>
                        <Info title={'Get Help'}
                              content={"<a href='tel:+1800120303303'>1800 120 303 303</a><a href='tel:+919121377123'>+91 91213 77123</a>"}
                              fontAwesomeIconName={faPhone}/>
                    </Col>
                </Row>
                <Row className={'footer-content'}>
                    <Col md={4} className={'footer-content-social'}><Social/></Col>
                    <Col md={5} className={'footer-content-copyright'}>
                        Copyright @ 2022
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default Footer
